export const cintakyu = {
    1: { cinta: "Blanca 1", kyu: "9", animal: "Koi", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_koi_fish._japanese_style_in_black_red_50dd13a4-4962-4a36-8a35-db579d5ffd5b_shvgk2.png" },
    2: { cinta: "Blanca 2", kyu: "9", animal: "Koi", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_koi_fish._japanese_style_in_black_red_50dd13a4-4962-4a36-8a35-db579d5ffd5b_shvgk2.png" },
    3: { cinta: "Blanca 3", kyu: "8", animal: "Koi", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_koi_fish._japanese_style_in_black_red_50dd13a4-4962-4a36-8a35-db579d5ffd5b_shvgk2.png" },
    4: { cinta: "Amarilla 1", kyu: "8", animal: "Kame", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536734/migruedav_badge_icon_with_turtle._japanese_style_in_black_red_w_57322849-c03d-4cfd-819a-3e56f3b64160_qxwtkv.png" },
    5: { cinta: "Amarilla 2", kyu: "7", animal: "Kame", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536734/migruedav_badge_icon_with_turtle._japanese_style_in_black_red_w_57322849-c03d-4cfd-819a-3e56f3b64160_qxwtkv.png" },
    6: { cinta: "Amarilla 3", kyu: "7", animal: "Kame", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536734/migruedav_badge_icon_with_turtle._japanese_style_in_black_red_w_57322849-c03d-4cfd-819a-3e56f3b64160_qxwtkv.png" },
    7: { cinta: "Naranja 1", kyu: "6", animal: "Hoo", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536735/migruedav_badge_icon_with_white_phoenix_bird._japanese_style_in_f88b02ed-30eb-4e09-948e-54a84c67f69f_cvpj2y.png" },
    8: { cinta: "Naranja 2", kyu: "6", animal: "Hoo", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536735/migruedav_badge_icon_with_white_phoenix_bird._japanese_style_in_f88b02ed-30eb-4e09-948e-54a84c67f69f_cvpj2y.png" },
    9: { cinta: "Naranja 3", kyu: "5", animal: "Hoo", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536735/migruedav_badge_icon_with_white_phoenix_bird._japanese_style_in_f88b02ed-30eb-4e09-948e-54a84c67f69f_cvpj2y.png" },
    10: { cinta: "Verde 1", kyu: "5", animal: "Hebi", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_snake._japanese_style_in_black_red_wh_3c2a8461-d1de-4940-aea2-8a013ae5dcf6_che17e.png" },
    11: { cinta: "Verde 2", kyu: "4", animal: "Hebi", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_snake._japanese_style_in_black_red_wh_3c2a8461-d1de-4940-aea2-8a013ae5dcf6_che17e.png" },
    12: { cinta: "Verde 3", kyu: "4", animal: "Hebi", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_snake._japanese_style_in_black_red_wh_3c2a8461-d1de-4940-aea2-8a013ae5dcf6_che17e.png" },
    13: { cinta: "Azul 1", kyu: "3", animal: "Tora", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536733/migruedav_badge_icon_with_tiger._japanese_style_in_black_red_wh_de65b8d0-8ca3-43cb-a23c-a66335dac795_ehuvcq.png" },
    14: { cinta: "Azul 2", kyu: "3", animal: "Tora", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536733/migruedav_badge_icon_with_tiger._japanese_style_in_black_red_wh_de65b8d0-8ca3-43cb-a23c-a66335dac795_ehuvcq.png" },
    15: { cinta: "Azul 3", kyu: "2", animal: "Tora", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536733/migruedav_badge_icon_with_tiger._japanese_style_in_black_red_wh_de65b8d0-8ca3-43cb-a23c-a66335dac795_ehuvcq.png" },
    16: { cinta: "Café 1", kyu: "2", animal: "Ryū", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536730/migruedav_badge_icon_with_dragon._japanese_style_in_black_red_w_b14cbb51-476d-44b7-afec-2ee2e59269f5_g1q3mh.png" },
    17: { cinta: "Café 2", kyu: "1", animal: "Ryū", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536730/migruedav_badge_icon_with_dragon._japanese_style_in_black_red_w_b14cbb51-476d-44b7-afec-2ee2e59269f5_g1q3mh.png" },
    18: { cinta: "Café 3", kyu: "1", animal: "Ryū", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536730/migruedav_badge_icon_with_dragon._japanese_style_in_black_red_w_b14cbb51-476d-44b7-afec-2ee2e59269f5_g1q3mh.png" },
    19: { cinta: "Negra 1", kyu: "1", animal: "Shisa", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536731/migruedav_badge_icon_with_shisa._japanese_style_in_black_red_wh_4c6c883b-3d51-4d11-a206-352eb901edc9_yqfdm1.png" },
    20: { cinta: "Negra 2", kyu: "2", animal: "Shisa", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536731/migruedav_badge_icon_with_shisa._japanese_style_in_black_red_wh_4c6c883b-3d51-4d11-a206-352eb901edc9_yqfdm1.png" },
    21: { cinta: "Negra 3", kyu: "3", animal: "Shisa", icon: "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536731/migruedav_badge_icon_with_shisa._japanese_style_in_black_red_wh_4c6c883b-3d51-4d11-a206-352eb901edc9_yqfdm1.png" },
  };