import React from "react";

function Do({ alumno }) {

  console.log(alumno.Nivel);
  const ready = false;
  return (
    ready ? <div className=" bg-black text-white w-full h-screen flex justify-center items-center">Coming Soon</div> :
      <section className="snap-x snap-mandatory flex flex-row text-white text-center overflow-x-auto">
        {/* NIVEL 1*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 1 ? 'blur-lg' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl">Nivel 1</h3>
            <p>Koi</p>
            <p className="w-3/4">“LA DANZA DE LA DETERMINACIÓN”</p>

            <img
              className="rounded-full w-52"
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1732988091/koi_w0jmrf.webp"
              alt="Koi"
            />
            <p>Cinta Blanca 1</p>
            <p>9 kyu</p>
          </div>
        </div>

        {/* NIVEL 2*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 2 ? 'blur-lg' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl">Nivel 2</h3>
            <p>Koi</p>

            <p className="p-10">
              "Al igual que un koi nada río arriba contra la corriente, tú iniciarás tu camino en el Karate Do con determinación, avanzando con confianza y superando obstáculos con gracia y fuerza."
            </p>
            <p>Cinta Blanca 2</p>
            <p>9 kyu</p>
          </div>
        </div>

        {/* NIVEL 3*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 3 ? 'blur-lg' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl">Nivel 3</h3>
            <p>Koi</p>

            <p className="p-10">
              "Has aprendido el valor de la{" "}
              <span className="text-gray-400">determinación</span> Este valor te dará la fuerza para lograr tus metas y afrontar las dificultades que se presenten en tu camino.
              "
            </p>
            <p>Cinta Blanca 3</p>
            <p>8 kyu</p>
          </div>
        </div>


        {/* NIVEL 4*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 4 ? 'blur-lg' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-yellow-400">Nivel 4</h3>
            <p>Kame</p>
            <p className="w-3/4">“LA ÉTICA DEL PASO FIRME”</p>

            <img
              className="rounded-full w-52"
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1732988091/kame_v05ph4.webp"
              alt="Koi"
            />
            <p className="text-yellow-400">Cinta Amarilla 1</p>
            <p>8 kyu</p>
          </div>
        </div>

        {/* NIVEL 5*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 5 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-yellow-400">Nivel 5</h3>
            <p>Kame</p>
            <p className="p-10">
              "Así como la tortuga nos revela la virtud de la paciencia en su caminar pausado y firme, tú entenderás que los pequeños avances harán de ti una persona con paso firme hacia tu cinta negra."
            </p>
            <p className="text-yellow-400">Cinta Amarilla 2</p>
            <p>7 kyu</p>
          </div>
        </div>

        {/* NIVEL 6*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 6 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-yellow-400">Nivel 6</h3>
            <p>Kame</p>

            <p className="p-10">
              "Has aprendido sobre{" "}
              <span className="text-yellow-400">paciencia y perseverancia</span> Con paciencia aceptarás, sin sentirte ansioso el tiempo que necesites para lograr tus objetivos y con perseverancia serás constante en tus entrenamientos sin importar lo difícil que puedan ser."
            </p>
            <p className="text-yellow-400">Cinta Amarilla 3</p>
            <p>7 kyu</p>
          </div>
        </div>

        {/* NIVEL 7*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 7 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-[#fc791e]">Nivel 7</h3>
            <p>Ho-o</p>
            <p className="w-3/4">“EL RENACER DEL ESPÍRITU“</p>

            <img
              className="rounded-full w-52 border-white border-8"
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1732988091/hoo_qnkhns.webp"
              alt="Ho-o"
            />
            <p className="text-[#fc791e]">Cinta Naranja 1</p>
            <p>6 kyu</p>
          </div>
        </div>

        {/* NIVEL 8*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 8 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-[#fc791e]">Nivel 8</h3>
            <p>Ho-o</p>

            <p className="p-10">
              "De la misma manera en que el Fénix renace de sus cenizas, aprovecharás tus caídas como oportunidades para levantarte con aún más energía y resistencia, entendiendo que cada caída te hará aún más fuerte."
            </p>
            <p className="text-[#fc791e]">Cinta Naranja 2</p>
            <p>6 kyu</p>
          </div>
        </div>

        {/* NIVEL 9*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 9 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-[#fc791e]">Nivel 9</h3>
            <p>Ho-o</p>

            <p className="p-10">
              "Has aprendido sobre{" "}
              <span className="text-[#fc791e]">resiliencia</span> podrás adaptarte y superar situaciones de adversidad con la confianza de que saldrás adelante."
            </p>
            <p className="text-[#fc791e]">Cinta Naranja 3</p>
            <p>5 kyu</p>
          </div>
        </div>
        {/* NIVEL 10*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 10 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-green-500">Nivel 10</h3>
            <p>Hebi</p>
            <p className="w-3/4">“EL ARTE DEL CONTROL Y LA AGILIDAD”</p>

            <img
              className="rounded-full w-52"
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1732988091/hebi_smqx9d.webp"
              alt="Hebi"
            />
            <p className="text-green-500">Cinta Verde 1</p>
            <p>5 kyu</p>
          </div>
        </div>

        {/* NIVEL 11*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 11 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-green-500">Nivel 11</h3>
            <p>Hebi</p>
            <p className="p-10">
              "Así como la serpiente, lograrás la madurez necesaria para adaptarte a los nuevos retos y moverte con fluidez y flexibilidad en las técnicas del Karate Do."
            </p>
            <p className="text-green-500">Cinta Verde 2</p>
            <p>4 kyu</p>
          </div>
        </div>
        {/* NIVEL 12*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 12 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-green-500">Nivel 12</h3>
            <p>Hebi</p>

            <p className="p-10">
              "Has aprendido sobre{" "}
              <span className="text-green-500">control y agilidad</span> podrás adaptarte y superar situaciones de adversidad con la confianza de que saldrás adelante."
            </p>

            <p className="text-green-500">Cinta Verde 3</p>
            <p>4 kyu</p>
          </div>
        </div>

        {/* NIVEL 13*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 13 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-blue-500">Nivel 13</h3>
            <p>Tora</p>
            <p className="w-3/4">“LA NATURALEZA DEL VALOR Y EL CORAJE”</p>

            <img
              className="rounded-full w-52"
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1732988091/tora_vbm7rs.webp"
              alt="Tora"
            />
            <p className="text-blue-500">Cinta Azul 1</p>
            <p>3 kyu</p>
          </div>
        </div>
        {/* NIVEL 14*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 14 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-blue-500">Nivel 14</h3>
            <p>Tora</p>

            <p className="p-10">
              "Tal como el tigre, permite que tu ferocidad interior te ayude a enfrentar tus miedos y los retos que surgirán en este nuevo nivel con valentía."
            </p>
            <p className="text-blue-500">Cinta Azul 2</p>
            <p>3 kyu</p>
          </div>
        </div>

        {/* NIVEL 15*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 15 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-blue-500">Nivel 15</h3>
            <p>Tora</p>

            <p className="p-10">
              "Has aprendido sobre{" "}
              <span className="text-blue-500">valentía y coraje</span> te harán una persona firme en tus decisiones, enfrentando tus miedos, inquietudes y dudas para emprender cosas audaces y difíciles"
            </p>
            <p className="text-blue-500">Cinta Azul 3</p>
            <p>2 kyu</p>
          </div>
        </div>
        {/* NIVEL 16*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 16 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-[#613127]">Nivel 16</h3>
            <p>Ryu</p>
            <p className="w-3/4">“EL CAMINO A LA SUPERACIÓN PERSONAL Y LA SABIDURÍA”</p>

            <img
              className="rounded-full w-52"
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1732988091/ryu_fprcfy.webp"
              alt="Ryu"
            />
            <p className="text-[#613127]">Cinta Café 1</p>
            <p>2 kyu</p>
          </div>
        </div>
        {/* NIVEL 17*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 17 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-[#613127]">Nivel 17</h3>
            <p>Ryu</p>
            <p className="p-10">
              "Del mismo modo que el dragón, continúa superando tus obstáculos y aún a ti mismo. Lograrás la sabiduría al entender que no existen límites físicos ni mentales en tu caminar."
            </p>
            <p className="text-[#613127]">Cinta Café 2</p>
            <p>1 kyu</p>
          </div>
        </div>

        {/* NIVEL 18*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 18 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-[#613127]">Nivel 18</h3>
            <p>Ryu</p>

            <p className="p-10">
              "Has aprendido sobre{" "}
              <span className="text-[#613127]">sabiduría y superación personal</span> que te ayudarán a conocerte más y entender tus logros y alcances. Te guiarán a alcanzar la perfección para encontrar la mejor versión de ti mismo. "
            </p>
            <p className="text-[#613127]">Cinta Café 3</p>
            <p>1 kyu</p>
          </div>
        </div>

        {/* NIVEL 19*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 19 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-gray-700">Nivel 19</h3>
            <p>Shisa</p>
            <p className="w-3/4">““EL ENCUENTRO DE TU EQUILIBRIO EMOCIONAL”
              ”</p>
            <img
              className="rounded-full w-52 border-white"
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536731/migruedav_badge_icon_with_shisa._japanese_style_in_black_red_wh_4c6c883b-3d51-4d11-a206-352eb901edc9_yqfdm1.png"
              alt="Shisa"
            />
            <p className="text-gray-700">CINTA NEGRA</p>
            <p>1 DAN</p>
          </div>
        </div>
        {/* NIVEL 20*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 20 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-gray-700">Nivel 20</h3>
            <p>Shisa</p>

            <p className="p-10">
              "Al igual que el Shisa, tendrás la habilidad de equilibrar la fuerza física con la sabiduría interior en el andar del Karate Do y de la vida."
            </p>
            <p className="text-gray-700">CINTA NEGRA 2</p>
            <p>2 DAN</p>
          </div>
        </div>

        {/* NIVEL 21*/}

        <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
          <div className={`${alumno.Nivel < 21 ? 'blur-md grayscale' : ''} snap-center min-w-full flex flex-col justify-center items-center gap-4 blur-0`}>
            <h3 className="text-2xl text-gray-700">Nivel 21</h3>
            <p>Shisa</p>

            <p className="p-10">
              "Has aprendido sobre{" "}
              <span className="text-gray-700">equilibrio</span> lo cual te hará una persona capaz de reaccionar con el estado de ánimo apropiado a cada situación.

              La superación de los objetivos alcanzados en estos años han forjado en ti un carácter de lucha y perseverancia y te han convertido en una persona capaz de enfrentar cualquier desafío."
            </p>
            <p className="text-gray-700">CINTA NEGRA 3</p>
            <p>3 DAN</p>
          </div>
        </div>
      </section>
  );
}

export default Do;
