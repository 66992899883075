import React from "react";
import Redeem from "./Redeem";

function TopBar({koins, level, diamonds,id}) {

  const koins_icon =
    "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1693943730/Captura_de_Pantalla_2023-09-05_a_la_s_13.55.09_fwx4dh.png";
  const diamonds_icon = 
  "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694407560/diamonds_hlvd5r.png"

  const animal = level <3 ? "Koi" : level <6 ? "Kame" : level <9 ? "Hoo" : level <12 ? "Hebi" : level <15 ? "Tora" : level <18 ? "Ryū" : "Shisa"
  const [redeemOpen, setRedeemOpen] = React.useState(false);

    return (
    <div className="w-full fixed top-0 grid grid-cols-3 px-4 py-6 content-center z-50">
      <div className="col-span-1 flex flex-col justify-center items-center gap-1">
        <div
          style={{ backgroundImage: `url(${koins_icon})` }}
          className="w-12 h-12 rounded-full bg-cover"
        ></div>
        <div className="text-white">{koins}</div>
      </div>
      <div className="col-span-1 flex flex-col justify-center items-center gap-1">
        <div className="text-white text-xl">Nivel {level}</div>
        <div className="text-white">{animal}</div>
      </div>
      <div className="col-span-1 flex flex-col justify-center items-center gap-1">
        <div
          style={{ backgroundImage: `url(${diamonds_icon})` }}
          className="w-12 h-12 rounded-full bg-cover"
          onClick={() => setRedeemOpen(true)}
        ></div>
        <div className="text-white">{diamonds}</div>
      </div>

        {redeemOpen&&<Redeem id = {id} setRedeemOpen={setRedeemOpen}/>}
    </div>
  );
}

export default TopBar;
